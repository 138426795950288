import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MuiSnackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import { amber, green } from '@material-ui/core/colors';

import sliceApp from '../../lib/reducers/app';

const variantIcon = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
}));

const Snackbar = () => {
  const classes = useStyles();
  const { message } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const {
    actions: { messageClear },
  } = sliceApp;
  const handleClose = () => dispatch(messageClear());
  const messageType = (message && message.type) || 'info';
  const Icon = variantIcon[messageType];

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={!!message}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx(classes[messageType])}
        aria-describedby="snackbar"
        message={(
          <span id="snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message && message.content}
          </span>
        )}
        action={[
          <CloseIcon
            key="close"
            aria-label="close"
            className={classes.icon}
            onClick={handleClose}
          />,
        ]}
      />
    </MuiSnackbar>
  );
};

export default Snackbar;
